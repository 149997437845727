.contact-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 30px 60px;
}

.contact-box {
  width: calc(50% - 60px);
  height: 27.5vh;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4vw;
  gap: 20px;
  color: black;
  transition: all 0.5s;
}

.contact-box:hover {
  transform: scale(1.1);
  color: white;
  background-color: black;
  transition: all 0.5s;
}

.toast-message {
  font-size: 1rem;
  max-width: 80vw !important;
}

@media only screen and (max-width: 800px) {
.contact-container {
    gap: 20px;
  }
  
.contact-box {
    width: 100%;
    height: 10vh;
    font-size: 4vh;
    gap: 20px;
  }
}