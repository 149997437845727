.background{
  height: 100vh;
  background: linear-gradient(135deg, #330867  50%, #30cfd0 0%);
  
  overflow: hidden;
}


.container-know-me{
  
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

}

.button-home{
  position: absolute;
  bottom: 30px;
  background: white;
  right: 30px;
  border-radius: 50%;
  padding: 20px;
  box-shadow: 0px 0px 10px 2px #888888;
  cursor: pointer;
  color: #888888;
  transition: all 0.5s;
}

.button-home:hover {
  color: white;
  background: black;
  transition: all 0.5s;
}

.button-know-me{
  width: 20vw;
  height: 20vw;
  background-color: white;
  border-radius: 50%;
  color: black;
  font-size: 2vw;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s;
}

.button-know-me:hover{
  
  background-color: black;
  color: white;
  transition: all 0.5s;
}

.container-content{
  display: flex;
  background: linear-gradient(135deg, #330867 , #30cfd0 );
  height: 100vh;
  animation: fade-in 1s;
}
@keyframes fade-in {
  from {
      opacity: 0;
}

  to {
      opacity: 1;
  }
}

.option-inner-content{
  background-color: white;
  width: 75%;
  border-radius: 0 10px 10px 0;
  animation: leftToRight 0.5s;
  height: 90vh;
  margin: 5vh 0;
  transition: all 0.5s;
}

.padding-content{
  padding: 2.5vh 5vh;
  animation: fadeIn 0.75s;
}

@keyframes fadeIn {
  from {
      opacity: 0;
}

  to {
      opacity: 1;
  }
}


@keyframes leftToRight {
  from {
      width: 0%;
}

  to {
      width: 75%;
  }
}

@keyframes topToBottom {
  from {
    height: 0vh;
}

  to {
    height: 70vh;
  }
}


.option-content-title {
  font-size: 2vw;
  line-height: 1.5;
  margin-top: 0;
}
.option-content-subtitle{
  
  font-size: 1.5vw;
}


@media only screen and (max-width: 800px) {
  .option-content-title {
    font-size: 2vh;
    line-height: 1.5;
  }
  .option-content-subtitle{
    
    line-height: 0;
    font-size: 1.5vh;
  }
  
  .padding-content{
    padding: 5vw;
  }
  .button-know-me{
    width: 20vh;
    height: 20vh;
    font-size: 2.5vh;
  }
  .container-content{
    display: flex;
    flex-direction: column;
  }
  .option-inner-content {
    width: 90vw;
    border-radius: 0 0 10px 10px;
    animation: topToBottom 0.5s;
    height: 70vh;
    margin: 0 5vw;
    transition: all 0.5s;
}
}