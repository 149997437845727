.about-description{
    font-size: 1.2vw;
    line-height: 1.5;
    text-align: justify;
    
  font-family: "Roboto Mono", monospace;
}

.list-container-flex{
    display: flex;
    justify-content: center;
    gap: 10vw;
}

li{
    line-height: 2;
    
    font-size: 1.2vw;
    font-family: "Roboto Mono", monospace;
}
.subtitle-about{
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
}
ul{
    margin: 0;
}

@media only screen and (max-width: 800px) {
    .about-description{
        font-size: 1.6vh;
        margin-bottom: 0;
    }
    .list-container{
        width: 50%;
    }
    li{
        font-size: 1.6vh;
        line-height: 2;
    }
}