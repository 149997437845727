.work-container {
  display: flex;
  width: 100%;
  overflow-x: auto;
  gap: 30px;
}
.work-item {
    background-color: white;
  display: flex;
  flex-direction: column;
  
  height: unset;
  padding-bottom: 70px;
  margin-bottom: 1.5vh;
  margin-top: 1.5vh;
  margin-left: 15px;
  min-width: 33%;
  width: 33%;
  box-shadow: 0px 3px 10px #aaaaaa;
  font-family: "Roboto Mono", monospace;
  position: relative;
}

.github-bubble {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0px 3px 10px #aaaaaa;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  cursor: pointer;
}

.work-content {
  background-color: white;
  height: 100%;
  padding: 0 30px;
  text-align: justify;
}

.work-content-title {
  text-align: center;
  font-size: 1.2vw;
  margin-bottom: 0;
}

.work-item > img {
  width: 100%;
}

.work-container::-webkit-scrollbar {
  height: 15px;
}

/* Track */

/* Handle */
.work-container::-webkit-scrollbar-thumb {
  background: linear-gradient(135deg, #330867, #30cfd0);
  border-radius: 30px;
}

@media only screen and (max-width: 800px) {
  .work-container {
    overflow-x: hidden;
    overflow-y: auto;
    gap: 30px;
    flex-wrap: wrap;
    height: calc(70vh - 35vw);
  }
  .work-item {
    min-width: 90%;
    width: 90%;
    margin-right: 15%;
  }

  .work-container::-webkit-scrollbar {
    width: 5px;
  }

  .work-content-title {
    text-align: center;
    font-size: 2vh;
    margin-bottom: 0;
  }

  .work-content-description {
    font-size: 1.4vh;
  }

  .work-content{
    
    padding: 0 15px
  }
}
